// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Variables
@import "variables";

// Bulma
@import "node_modules/bulma/bulma";

// Autocomplete 
@import "autocomplete";

// Form 
@import "form";

// Modal
@import "modal";

// Print
@import "print";

// Table
@import "table";

body {
	font-size: 12px;
}

.container {
	max-width: 100%;
	padding: 0 25px;
	width: 100%;
}

.control {
	position: static !important;
}

.fa {
	font-size: 16px;
}

.nav {
	.nav-item {
		font-size: 14px;
	}
}

.pagination {
	i {
		font-size: 12px;
	}
}

.red {
	background-color: red;
	color: $black;
}

.yellow {
	background-color: yellow;
	color: $black;
}

.blue {
	background-color: blue;
	color: $black;
}

#login form {
	width: 300px;
}

.header {
	.nav {
		height: auto;

		.nav-left {
			overflow: visible;
		}

		h1 {
			font-size: 150%;
			text-transform: uppercase;
		}

		.dropdown {
			ul {
				top: 44px;
			}
		}
	}
}

.dropdown {	
	position: relative;
	
	i {
		margin-left: 5px;
	}

	.nav-item {
		height: 100%;
	}

	&.show {
		ul {
			display: flex;
		}
	}

	ul {
		background-color: $white;
		box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
		display: none;
		flex-direction: column;
		position: absolute;
		width: 200px;
		z-index: 9999;

		li {
			&:hover,
			&.is-active {
				background-color: $turquoise;

				a {
					color: $white;
				}
			}			
		}
	}
}

.contact-card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	line-height: 2;
	margin: 20px 0 0 0;

	&:first-child {
		margin-top: 0;
	}

	.address {
		align-items: baseline;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		justify-content: center;

		div {
			align-items: center;
			display: flex;
			flex-direction: row;
			margin-left: 27px;

			&:first-child {
				margin-left: 0;
			}

			i {
				margin-right: 10px;
			}
		}
	}

	.phones {
		align-items: baseline;
		display: flex;
		flex-direction: column;

		.phone {
			align-items: center;
			display: flex;
			flex-direction: row;

			i {
				margin-right: 10px;
				width: 16px;
			}
		}
	}

	.notes {
		align-items: baseline;
		display: flex;
		flex-direction: row;

		i {
			margin-right: 10px;
			width: 16px;
		}
	}
}

.vdp-datepicker {
	input {
		align-items: center;
		background-color: white;
		border: 1px solid transparent;
		border-color: #dbdbdb;
		border-radius: 2px;
		box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
		color: #363636;
		display: inline-flex;
    	font-size: 0.75rem;
    	height: 2.25em;   
	    justify-content: flex-start;
	    line-height: 1.5;
	    max-width: 100%;
	    padding-bottom: calc(0.375em - 1px);
	    padding-left: calc(0.625em - 1px);
	    padding-right: calc(0.625em - 1px);
	    padding-top: calc(0.375em - 1px);
	    position: relative;
	    vertical-align: top;	    
	    width: 100%;
	}
}

html,body {
	height: 100%;
}

#login {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	position: relative;;
}

#legend {
	display: flex;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;

	ul {
		display: flex;
		flex-direction: row;
		
		li {
			padding: 5px 20px;
		}
	}
}

.nav-left {
	display: flex;
}

.nav-toggle {
	align-items: center;
	display: none;
	flex-direction: row;
	justify-content: center;
	width: auto;
}

.section {
	padding: 20px 0;

	+ .section {
		padding-top: 0;
	}
}

#title {
	font-size: 18px;
	margin-bottom: 0;
	margin-right: 20px;
	text-align: left;
}

.controls {
	display: flex;
	flex-direction: row;

	.button {
		margin: 0 5px;
	}
}

// Tablet
@import "tablet";