.modal {
	.modal-card {
		.modal-card-head {
			.modal-card-title {
				font-size: 16px;
			}
		}

		.modal-card-foot {
			justify-content: flex-end;
		}
	}
}