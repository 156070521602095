.autocomplete {
	.select {
		position: relative;
		width: 100%;

		&:after {
			cursor: pointer;
		}

		.input-container {
			position: absolute;
			z-index: 3;

			input {
				outline: none;
			}

			.suggestions {
				background-color: $white;
				border: 1px solid #00d1b2;
				border-top: none;
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
				box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
				display: none;
				max-height: 240px;
				overflow: auto;
				position: relative;
				z-index: 5;

				li {
					color: #363636;
					font-weight: normal;
					padding: 3px 5px;

					&:hover,
					&.active {
						background-color: #00d1b2;
						color: $white;
						cursor: pointer;
					}
				}
			}

			&.open-suggestions {
				.suggestions {					
					display: block;
				}
			}
		}

		select {
			z-index: 1;
		}
	}
}