html, body {
    overflow: auto;
}

.ia-report {
    tr {
        th, td {
            border: none;
            text-align: left;
        }
    }
}

@media screen and (max-width: 768px) {
    .header {
        .nav {        
            flex-direction: column;
            
            .nav-left {
                display: none;
                flex-direction: column;
                
                a {
                    width: 100%;
    
                    &:hover,
                    &.is-active {
                        background-color: $turquoise;
                        color: $white;
                    }
                }

                .dropdown {
                    display: flex;
                    flex-direction: column;

                    ul {
                        box-shadow: none;
                        display: none;
                        position: relative;
                        top: 0;
                        width: 100%;
                        z-index: 9999;

                        li {
                            padding-left: 25px;
                            width: 100%;
                        }
                    }

                    &.show {
                        ul {
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    .controls {
        flex-direction: column;

        .button {
            margin-bottom: 10px;
        }
    }

    #title {
        margin-bottom: 10px;
        margin-right: 0;
        text-align: center;
    }

	#controls {
		flex-direction: column;

        .button {
            margin: 5px 0;

            &:first-child {
                margin-top: 0;
            }
        }
	}

    .nav-toggle {
        display: flex;
    }
	
    table {
        border: 0;
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;

        caption {
            font-size: 1.3em;
        }

        thead {
            padding: 0;
        }

        tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;

            th,
            td {
                border-bottom: 1px solid #ddd;
                display: block;
                font-size: .8em;
                text-align: right;

                &:before {
                    content: attr(data-label);
                    float: left;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}