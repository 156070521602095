@media print {
	.header, .footer, .section {
		display: none;
	}

    body {
        height: 100%;
        overflow: visible !important;
        width: 100%;
    }

    .table {
        /*transform: translate(8.5in, -100%) rotate(90deg);
        transform-origin: bottom left;*/
        width: auto !important;
    }
}