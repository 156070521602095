.report {
	tr {
		th, td {
			border: 1px solid $black;
			padding: 5px;
			text-align: center;
			vertical-align: middle;
		}
	}
}

.ks-header {
	td {
		> div.line {
			border-bottom: 1px solid $black;
			margin-right: 40px;
		}

		.label-line-box {
			display: flex;
			flex-direction: row;

			div:first-child {
				width: 130px;
			}

			.line {
				border-bottom: 1px solid $black;
				flex: 1;
				margin-right: 40px;
			}
		}

		.line-label {
			margin-bottom: 20px;
		}
	}
}

.ia-header {
	td {
		> div.line {
			border-bottom: 1px solid $black;
		}

		.label-line-box {
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;
			
			.line-label {
				margin-right: 5px;
			}

			.line {
				border-bottom: 1px solid $black;
				flex: 1;				
			}
		}
	}
}

.table {
	thead {
		tr {
			th {				
				&.sortable {
					&:hover {
						cursor: pointer;
					}

					i {
						&.fa-sort-asc {
							position: relative;
							top: 4px;
						}

						&.fa-sort-desc {
							position: relative;
							top: -3px;
						}
					}
				}

				.filter {
					.control:first-child {
						width: 100%;
					}

					.button {
						font-size: 12px;
						height: 2.25em;
						line-height: 2.25em;

						.fa-remove {
							color: darken($red, 10%);
							font-size: 12px;
						}

						&:hover {
							border-color: $turquoise;
						}
					}
				}

				.vdp-datepicker__calendar {
					.cell:not(.blank):not(.disabled).day:hover,
					.cell:not(.blank):not(.disabled).month:hover,
					.cell:not(.blank):not(.disabled).year:hover {
						border-color: $turquoise;
					}
				}
			}
		}
	}

	tbody {
		tr {
			td {
				.input {
					font-size: 12px;
				}

				&.red {
					background-color: red;
				}

				&.yellow {
					background-color: yellow;
				}

				.controls {
					align-items: center;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;

					i {
						margin: 0 5px;

						&.fa-trash-o {
							color: lighten($black, 40%);

							&:hover {
								color: $black;
							}
						}

						&.fa-edit {
							margin-top: 1px;

							&:hover {
								color: darken($turquoise, 20%);
							}
						}
					}
				}	

				span {
					display: flex;
					max-width: 400px;
				}			
			}
		}
	}
}