form {
	.field {
		flex-direction: column;

		>div {
			display: flex;
			flex-direction: row;
		}


	}

	.controls {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 20px;

		.button {
			margin-left: 10px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.field {
	.label-container {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 5px;

		.label {
			align-items: center;
			display: flex;
			flex-direction: row;
			font-size: 12px;

			i.fa-plus {
				color: $green;
				cursor: pointer;
				font-size: 12px;
				margin-left: 10px;
			}

			.red-asterisk {
				color: red;
				font-weight: bold;
			}
		}
	}

	.control {
		font-size: 12px;
		position: relative;
		
		&:first-child {
			width: 100%;
		}

		.button {
			font-size: 12px;
			height: 2.25em;
			line-height: 2.25em;

			.fa-remove {
				color: darken($red, 10%);
				font-size: 12px;
			}

			&:hover {
				border-color: $turquoise;
			}
		}
		
		textarea {
			height: 125px;
		}

		.input {
			font-size: 12px;

			&.is-danger {
				border: 1px solid #dbdbdb;

				&:hover {
					border: 1px solid $red;
				}
			}
		}
	
		.vdp-datepicker {			
			input {
				border-bottom-left-radius: 3px;
    			border-top-left-radius: 3px;
    			border-top-right-radius: 0;
    			border-bottom-right-radius: 0;

				&:active,
				&:focus {
					border: 1px solid $turquoise !important;
				}

				&:hover {
					border: 1px solid #b5b5b5;
					z-index: 999;
				}
			}
		}
	}
}